import React, { useState, useEffect } from "react";
import "./App.css";
// import { TypeAnimation } from "react-type-animation";
import axios from "axios";
import { browserName } from "react-device-detect";
import Cookies from "js-cookie";
// import UserAgent from "user-agent";
import { v4 as uuidv4 } from "uuid";

const App = () => {
  // const ua = UserAgent.parse(navigator.userAgent);

  const HOST_NAME = "https://apicuration.aspirenow.in";
  // const HOST_NAME = "http://localhost:3900";
  const [text, setText] = useState("");
  const [reply, setReply] = useState(
    `Hello I am Tesa!, I can help you to curate habits for you`
  );
  const [deviceId, setDeviceId] = useState("");
  const isEnd = false;
  const [index, setIndex] = useState(0);
  const speed = 30;
  const [displayText, setDisplayText] = useState("");

  const [prevData, setPrevData] = useState([
    // { role: "user", content: "Hello" },
    // {
    //   role: "assistant",
    //   content: "Hello welcome, How can I assist you to help building habits",
    // },
  ]);

  useEffect(() => {
    // Cookies.remove("deviceInfo");
    // alert(window.navigator.userAgent);
    // alert(mobileModel);
    // alert(ua.name);
    // alert(ua.os);

    if (index < reply.length) {
      const interval = setInterval(() => {
        setDisplayText((prev) => prev + reply[index]);
        setIndex((prev) => prev + 1);
      }, speed);
      return () => clearInterval(interval);
    }

    if (!Cookies.get("deviceInfo")) {
      const createUser = async () => {
        await axios
          .post(`${HOST_NAME}/v2/create-user`, {
            deviceId: uuidv4(),
            deviceModel: browserName,
            location: "Tamilnadu",
          })
          .then((res) => {
            console.log(res.data.data.userData._id);
            Cookies.set("deviceInfo", res.data.data.userData._id);
            setDeviceId(res.data.data.userData._id);
            // alert(res.data.data.userData._id);
          })
          .catch((err) => console.log(err));
      };
      createUser();
    } else {
      setDeviceId(Cookies.get("deviceInfo"));
    }
  }, [text, index, reply]);

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      // const response = await sendChat(event.target.value);
      setText("");
      await axios
        .post(`${HOST_NAME}/externship/post-chat`, {
          questionName: event.target.value,
          deviceId: deviceId,
          isEnd: isEnd,
          prvData: prevData,
        })
        .then((res) => {
          console.log(res.data.data.questionResponseCreated.response);

          // const regex = /\{\s*isEnd:\s*true\s*\}/;

          // const match = regex.test(
          //   res.data.data.questionResponseCreated.response
          // );
          setDisplayText("");
          setIndex(0);
          // if (match) {
          //   setIsEnd(true);
          //   console.log("Daii");
          //   setReply(
          //     res.data.data.questionResponseCreated.response.replace(regex, "")
          //   );
          // setReply("Is this program relevant to you?");
          // } else {
          setReply(res.data.data.questionResponseCreated.response);
          // }

          // setReply(res.data.data.questionResponseCreated.response);
          setPrevData((prevData) => [
            ...prevData,

            {
              role: "user",
              content: res.data.data.questionResponseCreated.questionName,
            },
            {
              role: "assistant",
              content: res.data.data.questionResponseCreated.response,
            },
          ]);
          console.log(prevData);
        })
        .catch((err) => console.log(err));
    }
  };

  const convertNewlinesToBr = (text) => {
    return text.split("\n").join("<br />");
  };

  return (
    <div
      className="container"
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        height: "86vh",
        width: "98vw",
        marginBottom: "90px",
      }}
    >
      <div
        style={{
          width: "100%",
          zIndex: "800",
          position: "fixed",
          backgroundColor: "black",
        }}
      >
        <img
          alt="externship-logo"
          style={{
            marginTop: "-40px",
            width: "300px",
            height: "200px",
          }}
          src={require("./externship.png")}
        />
      </div>
      <div
        className="chat-container"
        style={{
          padding: "20px",
          width: "70%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          // border: "1px solid #fff"
          // borderRadius: "8px",
          marginTop: "150px",
          // boxShadow: "0px 0px 8px rgba(255,255,255,0.5)",
        }}
      >
        <p
          style={{
            fontSize: "1.6em",
            display: "inline-block",
            width: "70vw",
            color: "#fff",
            zIndex: "-100",
          }}
          dangerouslySetInnerHTML={{ __html: convertNewlinesToBr(displayText) }}
        ></p>

        <textarea
          className="input-text"
          type="text"
          value={text}
          rows="5"
          onChange={(event) => setText(event.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type here.."
          style={{
            width: "70vw",
            outline: "0",
            color: "#fff",
            borderWidth: "0 0 2px",
            borderColor: "rgba(255,255,255,0.2)",
            fontSize: "1.5em",
            // lineHeight: "1px",
            marginTop: "5%",
            marginBottom: "130px",

            // paddingBottom: "10px",
            // backgroundColor: "#0042a6",
            backgroundColor: "transparent",
          }}
        />
      </div>
      <img
        alt="background-png"
        style={{
          width: "98vw",
          height: "300px",
          position: "fixed",
          bottom: "-170px",
          zIndex: "10",
        }}
        src={require("./background.png")}
      />
    </div>
  );
};

export default App;
